import styled from "styled-components/macro";
import { COLORES } from "../../constants";

export const Container = styled.div`
  font-size: 0.8rem;
  padding: 1rem;
  background-color: ${COLORES.BACKGROUND};

  button {
    cursor: pointer;
  }
`;

export const Filters = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 1rem;
  margin-bottom: 1rem;
`;

export const Buttons = styled.div`
  display: flex;

  button,
  .button {
    font-size: 0.8rem;
    margin-right: 1rem;
    padding: 0.25rem 0.5rem;
    background-color: lightgrey;
    border: thin solid grey;
    border-radius: 0.2rem;
    cursor: pointer;
    box-sizing: border-box;

    :disabled {
      cursor: default;
    }
  }

  .hidden,
  input[type="file"] {
    display: none;
  }
  input {
    vertical-align: middle;
  }

  label {
    display: inline-block;
  }
  label[disabled] {
    color: darkgrey;
    background-color: lightgrey;
    cursor: default;
  }

  #uploadMovimientos {
    background-color: ${COLORES.BUTTON_BACKGROUND_DEFAULT};
  }
  #uploadMovimientos[disabled] {
    background-color: lightgrey;
  }

  #deleteMovimiento {
    background-color: #ff6666;
    :disabled {
      background-color: lightgrey;
    }
  }

  #generarPrefactura {
    background-color: ${COLORES.BUTTON_BACKGROUND_DEFAULT};
    :disabled {
      background-color: lightgrey;
    }
  }
`;

export const ShowDeleted = styled.div`
  margin: auto;
  margin-right: 0;

  label {
    vertical-align: middle;
  }

  button {
    margin: 0;
    margin-left: 1rem;
  }
`;

export const Table = styled.div`
  display: table;
  font-size: 0.6rem;
  border-collapse: collapse;
  margin: 1rem 0;
  table-layout: fixed;
  width: 100%;
`;

export const ColGroup = styled.div`
  display: table-column-group;
  div:first-child {
    width: 3%;
  }
  div:last-child {
    width: 10%;
  }
`;

export const Col = styled.div`
  display: table-column;
`;

export const Tr = styled.div`
  display: table-row;
  height: 1.75rem;
`;

export const Th = styled.div`
  display: table-cell;
  vertical-align: middle;
  border: thin solid ${COLORES.LIGHT_BORDER};
  font-weight: 600;
  padding-left: 0.5rem;
  color: ${COLORES.HEADER_TABLE_FONT};
  background-color: ${COLORES.HEADER_TABLE_BACKGROUND};

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const HeaderColumn = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 0.1rem;
`;

export const Pagination = styled.div`
  margin: 1rem 0;
  padding-bottom: 1rem;

  button {
    border: none;
    background-color: ${COLORES.BACKGROUND};
    color: ${COLORES.LINK};
    padding: 0;

    :disabled {
      color: ${COLORES.TEXT_DISABLED};
      cursor: default;
    }
  }
`;
