import axios from "axios";
import { loginRequest } from "../authConfig";
import { printVigenciaToken } from "../App";

const { NODE_ENV, REACT_APP_BASE_URL, REACT_APP_BASE_API_URL } = process.env;
export const DEVELOP = NODE_ENV === "development";

export const APP_BASE_URL = DEVELOP
  ? "http://localhost:3000"
  : REACT_APP_BASE_URL;
const BASE_API_URL = REACT_APP_BASE_API_URL;
const URL_AUTHENTICATE = "/Autorizar";

const axiosInstance = axios.create({
  baseURL: BASE_API_URL,
});

let requestInterceptorId;
let responseInterceptorId;

export let defaultSource;

const isTokenValid = (url = "empty_url", account) => {
  const currentTime = Date.now();
  const msalToken = localStorage.getItem("msalToken");
  const msalTokenExpiresOn = Number(localStorage.getItem("msalTokenExpiresOn"));
  const appToken = localStorage.getItem("appToken");
  const appTokenExpiresOn = Number(localStorage.getItem("appTokenExpiresOn"));

  if (DEVELOP) {
    console.group(`%c¿isTokenValid? ${url}`, "color: yellow");
    console.log(`%c[IReq] ${new Date(currentTime)}`, "color: cyan;");
    console.log(`[IReq] account`, account?.username);
    console.log(`[IReq] msalToken`, msalToken?.substring(0, 100));
    printVigenciaToken(msalTokenExpiresOn, "msalTokenExpiresOn", "IReq");
    console.log(`[IReq] appToken`, appToken?.substring(0, 100));
    printVigenciaToken(appTokenExpiresOn, "appTokenExpiresOn", "IReq");
  }

  if (url === URL_AUTHENTICATE) {
    if (account && msalToken && currentTime < msalTokenExpiresOn) {
      if (DEVELOP) {
        console.log(
          "%c[IReq] isTokenValid",
          "color: lightgreen; font-weight: bold"
        );
        console.groupEnd();
      }
      return true;
    }

    if (DEVELOP) {
      console.log("%c[IReq] !isTokenValid", "color: red; font-weight: bold");
      console.groupEnd();
    }
    return false;
  }

  // url !== URL_AUTHENTICATE
  if (
    account &&
    msalToken &&
    currentTime < msalTokenExpiresOn &&
    appToken &&
    currentTime < appTokenExpiresOn
  ) {
    if (DEVELOP) {
      console.log(
        "%c[IReq] isTokenValid",
        "color: lightgreen; font-weight: bold"
      );
      console.groupEnd();
    }
    return true;
  }

  if (DEVELOP) {
    console.log("%c[IReq] !isTokenValid", "color: red; font-weight: bold");
    console.groupEnd();
  }
  return false;
};

const waitForLocalStorage = (key, timeout = 5000, interval = 100) => {
  return new Promise((resolve, reject) => {
    if (localStorage.getItem(key) !== null) {
      return resolve(localStorage.getItem(key));
    }

    const startTime = Date.now();
    const checkInterval = setInterval(() => {
      if (localStorage.getItem(key) !== null) {
        clearInterval(checkInterval);
        resolve(localStorage.getItem(key));
      } else if (Date.now() - startTime > timeout) {
        clearInterval(checkInterval);
        reject(new Error(`Timeout: ${key} no encontrado en localStorage`));
      }
    }, interval);
  });
};

export const configureAxios = (msalInstance, setPermissions) => {
  DEVELOP && console.log("[configureAxios] Start");
  //region requestInterceptor
  if (requestInterceptorId !== undefined)
    axiosInstance.interceptors.request.eject(requestInterceptorId);

  let tokenFetching = false;
  const pendingRequestsQueue = [];

  const resolvePendingRequests = (newToken) => {
    DEVELOP && console.log("%cresolvePendingRequests", "color: yellow");
    pendingRequestsQueue.forEach(({ resolve, config }) => {
      DEVELOP && console.log(`%cResolviendo ${config.url}`, "color: magenta");
      const newConfig = { ...config };
      newConfig.headers.Authorization = `Bearer ${newToken}`;
      resolve(newConfig);
    });
    pendingRequestsQueue.length = 0;
  };

  const rejectPendingRequests = (error) => {
    DEVELOP && console.log("%crejectPendingRequests", "color: pink");
    pendingRequestsQueue.forEach(({ reject }) => reject(error));
    pendingRequestsQueue.length = 0;
  };

  requestInterceptorId = axiosInstance.interceptors.request.use(
    async (config) => {
      const account = msalInstance.getActiveAccount();
      let appToken = localStorage.getItem("appToken") || "";
      try {
        await waitForLocalStorage("started");
      } catch (error) {
        console.error("waitForLocalStorage", error);
      }

      if (isTokenValid(config.url, account)) {
        if (config.url !== URL_AUTHENTICATE)
          config.headers.Authorization = `Bearer ${appToken}`;
        return config;
      } else {
        if (tokenFetching) {
          // Si la renovación del token está en curso, encola la solicitud
          const promise = new Promise((resolve, reject) => {
            DEVELOP &&
              console.log(
                `%ctokenFetching, ${config.url} a la cola`,
                "color: magenta"
              );
            pendingRequestsQueue.push({ resolve, reject, config });
          });
          // Espera hasta que se resuelva la promesa para completar la llamada
          await promise;
          return config;
        } else {
          DEVELOP &&
            console.log(
              `%c!tokenFetching -> ${config.url} inicia obtención de nuevo(s) token(s)`,
              "color: magenta"
            );
          tokenFetching = true;
          try {
            const currentTime = Date.now();
            const msalTokenExtExpiresOn = Number(
              localStorage.getItem("msalTokenExtExpiresOn")
            );
            if (!msalTokenExtExpiresOn || currentTime > msalTokenExtExpiresOn) {
              DEVELOP &&
                console.log(
                  "%c[IReq] msalTokenExtExpiresOn expirado (o inexistente) => msalInstace.loginRedirect",
                  "color: pink"
                );
              msalInstance
                .loginRedirect(loginRequest)
                .catch((error) =>
                  console.error(
                    "[IReq] msalInstance.loginRedirect->error",
                    error
                  )
                );
              return new Promise(() => {});
            }

            const msalTokenExpiresOn = Number(
              localStorage.getItem("msalTokenExpiresOn")
            );
            const accessTokenRequest = { ...loginRequest, account };
            if (!msalTokenExpiresOn || currentTime > msalTokenExpiresOn) {
              DEVELOP &&
                console.log(
                  "%cmsalTokenExpiresOn expirado (o inexistente) => msalInstance.acquireTokenSilent",
                  "color: pink"
                );
              const resAcquireTokenSilent =
                await msalInstance.acquireTokenSilent(accessTokenRequest);
              const msalToken = resAcquireTokenSilent.accessToken;
              DEVELOP &&
                console.log(
                  "%cnuevo msalToken (acquireTokenSilent)",
                  "color: lightgreen",
                  msalToken.substring(0, 100)
                );
              const msalTokenExpiresOn = Number(
                resAcquireTokenSilent.expiresOn
              );
              const msalTokenExtExpiresOn = Number(
                resAcquireTokenSilent.extExpiresOn
              );
              localStorage.setItem("msalToken", msalToken);
              localStorage.setItem(
                "msalTokenExpiresOn",
                msalTokenExpiresOn.toString()
              );
              localStorage.setItem(
                "msalTokenExtExpiresOn",
                msalTokenExtExpiresOn.toString()
              );
            }

            if (config.url !== URL_AUTHENTICATE) {
              const appTokenExpiresOn = Number(
                localStorage.getItem("appTokenExpiresOn")
              );
              const msalToken = localStorage.getItem("msalToken") || "";
              if (!appToken || currentTime > appTokenExpiresOn) {
                DEVELOP &&
                  console.log(
                    "%cappToken expirado (o inexistente) => autenticar()",
                    "color: pink"
                  );
                const data = { Key: msalToken };
                const resAutenticar = await autenticar(data);
                appToken = resAutenticar.Token;
                DEVELOP &&
                  console.log(
                    "%cnuevo appToken (autenticar)",
                    "color: lightgreen",
                    msalToken.substring(0, 100)
                  );
                localStorage.setItem("appToken", appToken);
                localStorage.setItem(
                  "appTokenExpiresOn",
                  resAutenticar.RefreshToken
                );
                setPermissions(resAutenticar.AccesoModulo);
              }
            }

            config.headers.Authorization = `Bearer ${appToken}`;
            DEVELOP && console.log("%ctokenFetched", "color: magenta");
            tokenFetching = false;
            // Resuelve las promesas encoladas con el nuevo token
            resolvePendingRequests(appToken);
            return config;
          } catch (error) {
            console.error("[IReq] acquireTokenSilent | autenticar", error);
            DEVELOP && console.log("%ctokenFetching = false", "color: magenta");
            tokenFetching = false;
            // Rechaza las promesas encoladas con el error de renovación del token
            rejectPendingRequests(error);
            return Promise.reject(error);
          }
        }
      }
    },
    (error) => {
      console.error("[IReq] interceptors.request->error", error);
      return Promise.reject(error);
    }
  );

  //region responseInterceptor
  if (responseInterceptorId !== undefined)
    axiosInstance.interceptors.response.eject(responseInterceptorId);

  responseInterceptorId = axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      const originalRequest = error.config;
      if (error.response?.status === 401) {
        if (originalRequest.url === URL_AUTHENTICATE) {
          localStorage.setItem("appToken", "notAuthorized");
          window.location.href = `${APP_BASE_URL}/noAccess`;
        }
      }
      return Promise.reject(error);
    }
  );

  DEVELOP && console.log("[configureAxios] End");
};

const get = async (url) => {
  try {
    const response = await axiosInstance.get(url);
    return response.data || null;
  } catch (error) {
    if (error.response) {
      const res = {
        code: error.response.status,
        msg: error.response.data,
        error: error,
      };
      throw res;
    } else {
      const res_1 = { code: 500, msg: error.message, error: error };
      throw res_1;
    }
  }
};

const post = async (url, data, config) => {
  try {
    const response = await axiosInstance.post(url, data, config);
    return response.data || null;
  } catch (error) {
    if (axios.isCancel(error)) {
      DEVELOP && console.log("POST Request Cancelada");
    } else if (error.response) {
      const res = {
        code: error.response.status,
        msg: error.response.data,
        error: error,
      };
      throw res;
    } else {
      const res_1 = { code: 500, msg: error.message, error: error };
      throw res_1;
    }
  }
};

//#region SERVICE ENDPOINTS

export const autenticar = (data) => {
  const url = URL_AUTHENTICATE;
  return post(url, data);
};

export const obtenerAccionesUsuario = (data) => {
  const url = "/ObtenerAccionesUsuario";
  return post(url, data);
};

export const definicionPagina = (data) => {
  const url = "/DefinicionPagina";
  return post(url, data);
};

export const obtenerListas = (moduleName) => {
  const url = `/ObtenerListas/${moduleName}`;
  return get(url);
};

export const obtenerLista = (data) => {
  const url = "/ObtenerLista";
  return post(url, data);
};

export const obtenerFiltrosBusqueda = (moduleName) => {
  const url = `/ObtenerFiltrosBusqueda/${moduleName}`;
  return get(url);
};

export const generarAutorizacionReporte = (moduleName) => {
  const url = `/GenerarAutorizacionReporte`;
  return get(url);
};

//#region ION COMPROMETIDOS

export const obtenerComprometidos = (data) => {
  const url = "/ObtenerComprometidos";
  return post(url, data);
};

export const subirComprometidos = (data) => {
  const url = "/SubirComprometidos";
  return post(url, data);
};

export const subirComprometidosRevisados = (data) => {
  const url = "/SubirComprometidosRevisados";
  return post(url, data);
};

export const marcarFacturables = (data) => {
  const url = "/MarcarFacturables";
  return post(url, data);
};

export const generarExcelComprometidos = (data) => {
  const url = "/GenerarExcelComprometidos";
  return post(url, data);
};

export const generarExcelFacturacion = (data) => {
  const url = "/GenerarExcelFacturacion";
  return post(url, data);
};

export const obtenerResumen = (data) => {
  const url = "/ObtenerResumen";
  return post(url, data);
};

export const generarPlantillaCargarGastosComprometidos = () => {
  const url = `/GenerarPlantillaCargarGastosComprometidos`;
  return get(url);
};

//#region MOVIMIENTOS
export const obtenerMovimientos = (data) => {
  const url = "/ObtenerMovimientos";
  return post(url, data);
};

export const guardarMovimiento = (data) => {
  const url = "/GuardarMovimiento";
  return post(url, data);
};

export const subirMovimientos = (data) => {
  const url = "/SubirMovimientos";
  return post(url, data);
};

export const eliminarMovimientos = (data) => {
  const url = "/EliminarMovimientos";
  return post(url, data);
};

export const recuperarMovimientos = (data) => {
  const url = "/RecuperarMovimientos";
  return post(url, data);
};

export const revisionPromociones = (data) => {
  const url = "/RevisionPromociones";
  return post(url, data);
};

export const traerArchivosMovimientos = () => {
  const url = `/TraerArchivosMovimientos`;
  return post(url);
};

export const eliminarArchivoMovimientos = (data) => {
  const url = `/EliminarArchivoMovimientos`;
  return post(url, data);
};

export const generarPrefacturaArchivo = (data) => {
  const url = `/GenerarPrefacturaArchivo`;
  return post(url, data);
};

export const generarPlantillaCargarGastos = () => {
  const url = `/GenerarPlantillaCargarGastos`;
  return get(url);
};

export const validacionGastosDuplicados = (data) => {
  const url = "/ValidacionGastosDuplicados";
  return post(url, data);
};

export const existeGastosPromocion = (data) => {
  const url = "/ExisteGastosPromocion";
  return post(url, data);
};

export const actualizacionProrrateo = (data) => {
  const url = "/ActualizacionProrrateo";
  return post(url, data);
};

export const obtenerPosiblesPrefacturas = (data) => {
  const url = "/ObtenerPosiblesPrefacturas";
  return post(url, data);
};

//#region PREFACTURAS

export const generarPrefactura = (data) => {
  const url = "/GenerarPrefactura";
  return post(url, data);
};

export const obtenerPrefacturas = (data) => {
  const url = "/ObtenerPrefacturas";
  return post(url, data);
};

export const obtenerPrefactura = (data) => {
  const url = "/ObtenerPrefactura";
  return post(url, data);
};

export const anularPrefactura = (data) => {
  const url = "/AnularPrefactura";
  return post(url, data);
};

export const desvincularMovimientos = (data) => {
  const url = "/DesvincularMovimientos";
  return post(url, data);
};

export const vincularMovimientos = (data) => {
  const url = "/VincularMovimientos";
  return post(url, data);
};

export const guardarComentario = (data) => {
  const url = "/GuardarComentario";
  return post(url, data);
};

export const eliminarComentario = (data) => {
  const url = "/EliminarComentario";
  return post(url, data);
};

export const exportarPrefactura = (data) => {
  const url = "/ExportarPrefactura";
  return post(url, data);
};

export const generarOnBase = (idPrefactura) => {
  const url = `/GenerarOnBase/${idPrefactura}`;
  return get(url);
};

export const validarPrefacturaProveedor = (data) => {
  const url = "/ValidarPrefacturaProveedor";
  return post(url, data);
};

export const rechazarPrefacturaProveedor = (data) => {
  const url = "/RechazarPrefacturaProveedor";
  return post(url, data);
};

//#region AUTORIZACIONES

export const obtenerAutorizacionesPrefacturas = (data) => {
  const url = "/ObtenerAutorizacionesPrefacturas";
  return post(url, data);
};

export const crearAutorizacion = (data) => {
  const url = "/CrearAutorizacion";
  return post(url, data);
};

export const borrarAutorizacion = (data) => {
  const url = "/BorrarAutorizacion";
  return post(url, data);
};

export const cancelarAutorizacion = (data) => {
  const url = "/CancelarAutorizacion";
  return post(url, data);
};

export const darAutorizacion = (data) => {
  const url = "/DarAutorizacion";
  return post(url, data);
};

//#region PROVEEDORES

export const obtenerProveedores = (data) => {
  defaultSource = axios.CancelToken.source();
  const url = "/ObtenerProveedores";
  return post(url, data, { cancelToken: defaultSource.token });
};

export const obtenerProveedor = (data) => {
  const url = `/ObtenerProveedor/${data}`;
  return get(url);
};

export const eliminarProveedor = (data) => {
  const url = "/EliminarProveedor";
  return post(url, data);
};

export const desvincularProveedorArea = (data) => {
  const url = "/DesvincularProveedorArea";
  return post(url, data);
};

export const vincularProveedorArea = (data) => {
  const url = "/VincularProveedorArea";
  return post(url, data);
};

export const guardarProveedor = (data) => {
  const url = "/GuardarProveedor";
  return post(url, data);
};

export const validarProveedorArea = (data) => {
  const url = "/ValidarProveedorArea";
  return post(url, data);
};

export const invalidarProveedorArea = (data) => {
  const url = "/InvalidarProveedorArea";
  return post(url, data);
};

//#region PARTIDAS

export const obtenerPartidas = (data) => {
  defaultSource = axios.CancelToken.source();
  const url = "/ObtenerPartidas";
  return post(url, data, { cancelToken: defaultSource.token });
};

export const obtenerPartida = (data) => {
  const url = `/ObtenerPartida/${data}`;
  return get(url);
};

export const eliminarPartida = (data) => {
  const url = "/EliminarPartida";
  return post(url, data);
};

export const guardarPartida = (data) => {
  const url = "/GuardarPartida";
  return post(url, data);
};

//#region ÁREAS

export const obtenerAreas = (data) => {
  const url = "/ObtenerAreas";
  return post(url, data);
};

export const guardarArea = (data) => {
  const url = "/GuardarArea";
  return post(url, data);
};

export const eliminarArea = (data) => {
  const url = "/EliminarArea";
  return post(url, data);
};

//#region ÁREAS ION
export const obtenerIONAreas = (data) => {
  const url = "/ObtenerIONAreas";
  return post(url, data);
};

export const guardarIONArea = (data) => {
  const url = "/GuardarIONArea";
  return post(url, data);
};

export const eliminarIONArea = (data) => {
  const url = "/EliminarIONArea";
  return post(url, data);
};

//#region AUDITORIA

export const obtenerAuditoria = (data) => {
  const url = "/ObtenerAuditoria";
  return post(url, data);
};

//#region ROLES

export const obtenerRoles = (data) => {
  const url = "/ObtenerRoles";
  return post(url, data);
};

export const obtenerRol = (idRol) => {
  const url = `/ObtenerRol/${idRol}`;
  return get(url);
};

export const guardarRol = (data) => {
  const url = "/GuardarRol";
  return post(url, data);
};

//#region USUARIOS

export const obtenerUsuarios = (data) => {
  defaultSource = axios.CancelToken.source();
  const url = "/ObtenerUsuarios";
  return post(url, data, { cancelToken: defaultSource.token });
};

export const obtenerUsuario = (idUsuario) => {
  const url = `/ObtenerUsuario/${idUsuario}`;
  return get(url);
};

export const guardarUsuario = (data) => {
  const url = "/GuardarUsuario";
  return post(url, data);
};

export const eliminarUsuario = (data) => {
  const url = "/EliminarUsuario";
  return post(url, data);
};
