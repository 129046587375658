import * as S from "./styles";
import { FieldText } from "../../components/FieldText";
import { Loading } from "../../components/Loading";
import CustomTable from "../../components/CustomTable/Table";
import { FieldListSearch } from "../../components/FieldListSearch";
import { FieldList } from "../../components/FieldList";
import { FieldNumber } from "../../components/FieldNumber";
import { SummaryTable } from "../../components/Ion/SummaryTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faUpload } from "@fortawesome/free-solid-svg-icons";
import { FieldDate } from "../../components/FieldDate";

export const EngagedSectionComponent = ({
  loading,
  engagedFormData,
  setEngagedFormData,
  openPopUpLoadFile,
  permissions,
  exercise,
  area,
  handleSendEngaged,
  handleEngagedDataChange,
  exportEngagedData,
  cargarArchivoComprometidoRevisado,
  openPopUpLoadFileComprometidoRevisado,
  cargarComprometidoRevisado,
  totalStatusSummary,
  recalcularProrrateos,
  isAdminIon,
  typeBillable,
  handleTypeBillableSelect,
  billableDates,
  handleBillableDatesChange,
  listTypeFacturable,
  facturado,
  handleFacturadoSelect,
  listFacturado,
}) => (
  <>
    <Loading hidden={!loading} />
    <S.AccordionContent twoColumns>
      <S.LeftColumn>
        <S.AccordionSectionComprometido1>
          <FieldText
            id="engaged"
            type="text"
            value={engagedFormData.engagedFileName || ""}
            handleChange={() => setEngagedFormData(engagedFormData)}
            label="Comprometido"
            styles="accordion"
          />
          <S.Buttons>
            <button
              onClick={openPopUpLoadFile}
              disabled={!permissions?.IONComprometido?.Editar}
            >
              <FontAwesomeIcon icon={faUpload} size="lg" />
              Cargar Fichero
            </button>
          </S.Buttons>
          <S.Buttons>
            <button
              disabled={
                !!!engagedFormData.engagedFileName ||
                !!!exercise ||
                !!!area ||
                !permissions?.IONComprometido?.Editar
              }
              onClick={handleSendEngaged}
            >
              <FontAwesomeIcon icon={faPlusCircle} size="lg" />
              Cargar Comprometido
            </button>
          </S.Buttons>
          <S.Checkboxes className="checkbox">
            <input
              id="prorrateo"
              type="checkbox"
              checked={engagedFormData.prorrateo ? true : false}
              onChange={(e) => {
                handleEngagedDataChange(e.target.checked, "prorrateo");
              }}
              disabled={!permissions?.IONComprometido?.Editar}
            />
            <label htmlFor="prorrateo" className="checkbox">
              Prorratear
            </label>
          </S.Checkboxes>
        </S.AccordionSectionComprometido1>

        <S.AccordionSectionComprometido2>
          <S.AccordionSectionComprometido2Inputs>
            <S.Checkboxes>
              <input
                id="exportAmount"
                type="checkbox"
                checked={engagedFormData.exportAmount ? true : false}
                onChange={(e) => {
                  handleEngagedDataChange(e.target.checked, "exportAmount");
                }}
                disabled={!permissions?.IONComprometido?.Editar}
              />
              <label htmlFor="exportAmount" className="checkbox">
                Exportar Acumulado
              </label>
            </S.Checkboxes>

            <S.Checkboxes>
              <input
                id="exportGestor"
                type="checkbox"
                checked={engagedFormData.exportGestor ? true : false}
                onChange={(e) => {
                  handleEngagedDataChange(e.target.checked, "exportGestor");
                }}
                disabled={!permissions?.IONComprometido?.Editar}
              />
              <label htmlFor="exportGestor" className="checkbox">
                Exportar Gestor
              </label>
            </S.Checkboxes>
            <S.InputListFacturado>
              <FieldList
                id="facturado"
                type="enum"
                label="Facturable"
                currentOption={facturado}
                handleChange={handleFacturadoSelect}
                options={listFacturado.Valores}
                canBeEmpty={true}
                readOnly={false}
                required={false}
              />
            </S.InputListFacturado>
            <S.InputListFacturable>
              <FieldList
                id="typeBillable"
                type="enum"
                label="Tipo de facturable"
                currentOption={typeBillable}
                handleChange={handleTypeBillableSelect}
                options={listTypeFacturable.Valores}
                canBeEmpty={true}
                readOnly={false}
                required={false}
              />
            </S.InputListFacturable>
            <S.InputsDate>
              <FieldDate
                key="fechaDesde"
                id="fechaDesde"
                label="Fecha desde comprometido"
                value={billableDates?.fechaDesde || ""}
                handleChange={(id, e) => {
                  handleBillableDatesChange("fechaDesde", e);
                }}
                readOnly={false}
                required={false}
                styles="filter"
              />
              <FieldDate
                key="fechaHasta"
                id="fechaHasta"
                label="Fecha hasta comprometido"
                value={billableDates?.fechaHasta || ""}
                handleChange={(id, e) => {
                  handleBillableDatesChange("fechaHasta", e);
                }}
                readOnly={false}
                required={false}
                styles="filter"
              />
            </S.InputsDate>
          </S.AccordionSectionComprometido2Inputs>
          <S.Buttons>
            <button
              disabled={
                !permissions?.IONComprometido?.Exportar ||
                (!engagedFormData.exportAmount && !engagedFormData.exportGestor)
              }
              onClick={exportEngagedData}
            >
              <FontAwesomeIcon icon={faPlusCircle} size="lg" />
              Exportar Datos Comprometidos
            </button>
          </S.Buttons>
        </S.AccordionSectionComprometido2>

        <S.AccordionSectionComprometido3>
          <FieldText
            id="engagedReviewed"
            type="text"
            value={
              cargarArchivoComprometidoRevisado.engagedFileName
                ? cargarArchivoComprometidoRevisado.engagedFileName
                : ""
            }
            handleChange={() => setEngagedFormData(engagedFormData)}
            label="Comprometido Revisado"
            styles="accordion"
          />
          <S.Buttons>
            <button
              onClick={openPopUpLoadFileComprometidoRevisado}
              disabled={!permissions?.IONComprometido?.Editar}
            >
              <FontAwesomeIcon icon={faUpload} size="lg" />
              Cargar Fichero
            </button>
          </S.Buttons>
          <S.Buttons>
            <button
              disabled={
                !!!cargarArchivoComprometidoRevisado.engagedFileName ||
                !!!exercise ||
                !!!area ||
                !permissions?.IONComprometido?.Exportar
              }
              onClick={cargarComprometidoRevisado}
            >
              <FontAwesomeIcon icon={faPlusCircle} size="lg" />
              Cargar Comprometido revisado
            </button>
          </S.Buttons>
          {isAdminIon && (
            <S.Checkboxes>
              <input
                id="saltarValidaciones"
                type="checkbox"
                checked={engagedFormData.skipValidations ? true : false}
                onChange={(e) => {
                  handleEngagedDataChange(e.target.checked, "skipValidations");
                }}
              />
              <label htmlFor="saltarValidaciones" className="checkbox">
                Saltar validaciones
              </label>
            </S.Checkboxes>
          )}
        </S.AccordionSectionComprometido3>

        {isAdminIon && (
          <S.AccordionSectionComprometido4>
            <S.Buttons>
              <button
                onClick={recalcularProrrateos}
                disabled={!permissions?.IONComprometido?.Editar}
              >
                Recalcular Prorrateos
              </button>
            </S.Buttons>
          </S.AccordionSectionComprometido4>
        )}
      </S.LeftColumn>

      <S.RightColumn>
        <S.AccordionSectionComprometidoResumen>
          <SummaryTable totalStatusSummary={totalStatusSummary} />
        </S.AccordionSectionComprometidoResumen>
      </S.RightColumn>
    </S.AccordionContent>
  </>
);

export const BillableSectionComponent = ({
  loading,
  suplier,
  handleSupplierSelect,
  area,
  handleLoadBillable,
  permissions,
  exportOptionsState: { exportOptions, setExportOptions },
  handleExportSelected,
  totalStatusSummary,
}) => {
  const billableType = [
    { id: "PF", label: "Prefacturas PF" },
    { id: "SP", label: "Suplidos SP" },
    { id: "FD", label: "Factura Directa FD" },
    { id: "NF", label: "No Facturable NF" },
    { id: "SF", label: "Sin Factura SF" },
  ];

  return (
    <>
      <Loading hidden={!loading} />
      <S.AccordionContent twoColumnsAsymmetric>
        <S.LeftColumn>
          <S.AccordionSectionFacturable1>
            <S.Buttons billableSection>
              <button
                onClick={handleLoadBillable}
                disabled={!permissions?.IONFacturable?.Exportar}
              >
                <FontAwesomeIcon icon={faPlusCircle} size="lg" />
                Cargar Facturable
              </button>
            </S.Buttons>
          </S.AccordionSectionFacturable1>
        </S.LeftColumn>

        <S.RightColumn>
          <S.AccordionSectionFacturable2>
            <S.FieldListSearchWrapper>
              <FieldListSearch
                id="BillableProveedor"
                label="Proveedor"
                listName="ProveedoresION"
                currentOption={suplier}
                handleChange={handleSupplierSelect}
                canBeEmpty={true}
                readOnly={false}
                required={false}
                parent={"Area"}
                idParent={area}
              />
            </S.FieldListSearchWrapper>

            <S.Buttons billableSection>
              <button
                onClick={handleExportSelected}
                disabled={
                  !exportOptions.PF &&
                  !exportOptions.SP &&
                  !exportOptions.FD &&
                  !exportOptions.NF &&
                  !exportOptions.SF
                }
              >
                <FontAwesomeIcon icon={faPlusCircle} size="lg" />
                Exportar
              </button>
            </S.Buttons>

            <S.CheckboxesVertical>
              {billableType.map((bt) => {
                const hasBillableType = totalStatusSummary.some(
                  (e) => e.TipoFacturable === bt.id
                );

                return (
                  <S.Checkbox
                    key={bt.id}
                    title={hasBillableType ? "" : "No hay registros"}
                  >
                    <input
                      id={bt.id}
                      type="checkbox"
                      checked={exportOptions[bt.id] || false}
                      onChange={(e) =>
                        setExportOptions({
                          ...exportOptions,
                          [bt.id]: e.target.checked,
                        })
                      }
                      disabled={!hasBillableType}
                    />
                    <label htmlFor={bt.id} className="checkbox">
                      {bt.label}
                    </label>
                  </S.Checkbox>
                );
              })}
            </S.CheckboxesVertical>

            <S.CheckboxesVertical>
              {exportOptions.PF && (
                <S.Checkbox>
                  <input
                    id="cargaDirectaPF"
                    type="checkbox"
                    checked={exportOptions.CDPF}
                    onChange={(e) =>
                      setExportOptions({
                        ...exportOptions,
                        CDPF: e.target.checked,
                      })
                    }
                  />
                  <label htmlFor="cargaDirectaPF" className="checkbox">
                    Carga Directa PF
                  </label>
                </S.Checkbox>
              )}
            </S.CheckboxesVertical>
          </S.AccordionSectionFacturable2>
        </S.RightColumn>
      </S.AccordionContent>
    </>
  );
};

export const ListSectionComponent = ({
  loading,
  suplierFilter,
  handleSupplierFilterSelect,
  area,
  metadataRegisters,
  registers,
  page,
  setPage,
  listSize,
  stateOT,
  stateOTList,
  handleStateOTSelect,
  numberOT,
  handleChangeNumberOT,
}) => (
  <>
    <Loading hidden={!loading} />
    <S.AccordionContent>
      <S.AccordionSectionListado>
        <div>
          <S.Filters count={3}>
            <FieldListSearch
              id="ListSectionProveedor"
              label="Proveedor"
              listName="ProveedoresION"
              currentOption={suplierFilter}
              handleChange={handleSupplierFilterSelect}
              canBeEmpty={true}
              readOnly={false}
              required={false}
              parent={"Area"}
              idParent={area}
            />
            <FieldList
              id="stateOT"
              type="enum"
              label="Estado OT"
              currentOption={stateOT}
              options={stateOTList}
              handleChange={handleStateOTSelect}
              canBeEmpty={true}
              readOnly={false}
              required={false}
            />
            <FieldNumber
              id="numberOT"
              label="Número OT"
              value={numberOT || 0}
              empty={numberOT === ""}
              handleChange={handleChangeNumberOT}
              type="int"
              readOnly={false}
              required={false}
              styles="filter"
            />
          </S.Filters>

          <CustomTable
            metadataRegisters={metadataRegisters}
            data={registers}
            page={page}
            setPage={setPage}
            listSize={listSize}
          />
        </div>
      </S.AccordionSectionListado>
    </S.AccordionContent>
  </>
);
