import React, { useState, useEffect } from "react";
import * as S from "./styles";
import * as API from "../../api";
import { toast } from "react-toastify";
import { Loading } from "../../components/Loading";
import { RegistroPrefactura } from "../../components/RegistroPrefactura";
import { Sort } from "../../components/Sort";
import { AUTORIZACIONES_POR_PAGINA } from "../../constants";
import { usePermissions } from "../../context";

export const Autorizaciones = () => {
  const { permissions } = usePermissions();
  const [loading, setLoading] = useState(true);
  const [autorizaciones, setAutorizaciones] = useState([]);
  const [metadataTable, setMetadataTable] = useState([]);
  const [nextPage, setNextPage] = useState(0);
  const [sortActive, setSortActive] = useState({});
  const [triggerGetAutorizaciones, setTriggerGetAutorizaciones] =
    useState(true);
  useEffect(() => {
    let isMounted = true;

    API.definicionPagina({
      Modulo: "Autorizacion_Prefacturas",
      TipoDefinicion: "grilla",
    })
      .then((res) => isMounted && setMetadataTable(res))
      .catch((error) => API.DEVELOP && console.log(error));

    return () => (isMounted = false);
  }, []);
  useEffect(() => {
    let isMounted = true;

    triggerGetAutorizaciones && isMounted && getAutorizaciones();

    return () => (isMounted = false);
  }, [triggerGetAutorizaciones]); // eslint-disable-line react-hooks/exhaustive-deps

  const getAutorizaciones = (page = 1) => {
    setLoading(true);
    const data = {};
    data.Paginacion = getPagination(page);

    API.obtenerAutorizacionesPrefacturas(data)
      .then((res) => {
        if (page === 1) setAutorizaciones(res);
        else setAutorizaciones([...autorizaciones, ...res]);

        if (res.length < AUTORIZACIONES_POR_PAGINA) setNextPage(0);
        else setNextPage(page + 1);
      })
      .catch((error) => {
        if (error.code !== 401)
          toast.error("Error al obtener Autorizaciones: " + error.msg?.Message);
        API.DEVELOP && console.log(error);
      })
      .finally(() => setLoading(false));

    setTriggerGetAutorizaciones(false);
  };

  const getPagination = (page) => ({
    Pagina: page,
    CantidadRegistros: AUTORIZACIONES_POR_PAGINA,
  });

  const sort = (field, value) => {
    setSortActive({
      IdCampo: field,
      Orden: value,
    });
    setTriggerGetAutorizaciones(true);
  };

  return (
    <S.Container>
      <Loading hidden={!loading} />
      <S.Table>
        <S.ColGroup>
          <S.Col />
          {metadataTable.map((campo) => (
            <S.Col key={campo.id} />
          ))}
          <S.Col />
        </S.ColGroup>
        <S.Tr>
          <S.Th />
          {metadataTable.map((campo) => (
            <S.Th key={campo.id} title={campo.etiqueta}>
              <S.HeaderColumn>
                <span>{campo.etiqueta}</span>
                {campo.ordenable && (
                  <Sort
                    field={campo.nombreCampo}
                    sortActive={sortActive}
                    onClick={(value) => sort(campo.nombreCampo, value)}
                  />
                )}
              </S.HeaderColumn>
            </S.Th>
          ))}
          <S.Th />
        </S.Tr>
        {autorizaciones.map((prefactura) => (
          <RegistroPrefactura
            key={prefactura.id}
            metadataTable={metadataTable}
            prefactura={prefactura}
            type="autorizaciones"
            update={() => setTriggerGetAutorizaciones(true)}
            toast={toast}
            setLoading={setLoading}
            access={permissions}
          />
        ))}
      </S.Table>
      <S.Pagination>
        <button
          disabled={nextPage === 0}
          onClick={() => getAutorizaciones(nextPage)}
        >
          {nextPage === 0
            ? "No hay más autorizaciones"
            : "Mostrar más autorizaciones..."}
        </button>
      </S.Pagination>
    </S.Container>
  );
};
