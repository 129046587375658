import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import * as S from "./styles";
import * as API from "../../api";
import { toast } from "react-toastify";
import { Loading } from "../../components/Loading";
import { FieldList } from "../../components/FieldList";
import { FieldListSearch } from "../../components/FieldListSearch";
import { FieldText } from "../../components/FieldText";
import { FieldDate } from "../../components/FieldDate";
import { RegistroPrefactura } from "../../components/RegistroPrefactura";
import { Sort } from "../../components/Sort";
import { PREFACTURAS_POR_PAGINA } from "../../constants";
import { usePermissions } from "../../context";

export const Prefacturas = () => {
  const { permissions } = usePermissions();
  const [loading, setLoading] = useState(true);
  const [prefacturas, setPrefacturas] = useState([]);
  const [metadataTable, setMetadataTable] = useState([]);
  const [metadataFilters, setMetadataFilters] = useState([]);
  const [listas, setListas] = useState({});
  const [filters, setFilters] = useState({});
  const [nextPage, setNextPage] = useState(0);
  const [showAnnulled, setShowAnnulled] = useState(false);
  const [sortActive, setSortActive] = useState({});
  const [esProveedor, setEsProveedor] = useState(false);
  const [triggerGetPrefacturas, setTriggerGetPrefacturas] = useState(true);
  useEffect(() => {
    let isMounted = true;

    API.definicionPagina({ Modulo: "Prefacturas", TipoDefinicion: "grilla" })
      .then((res) => isMounted && setMetadataTable(res))
      .catch((error) => API.DEVELOP && console.log(error));

    API.obtenerFiltrosBusqueda("Prefacturas")
      .then((res) => isMounted && setMetadataFilters(res))
      .catch((error) => API.DEVELOP && console.log(error));

    API.obtenerListas("Prefacturas")
      .then((res) => isMounted && setListas(res))
      .catch((error) => API.DEVELOP && console.log(error));

    return () => (isMounted = false);
  }, []);

  const getPrefacturas = useCallback(
    (page = 1) => {
      const data = {};
      data.Paginacion = getPagination(page);
      data.MostrarEliminados = showAnnulled;
      if (Object.keys(sortActive).length > 0) data.Ordenacion = sortActive;
      if (Object.keys(filters).length > 0)
        Object.entries(filters).forEach(([nameFilter, valueFilter]) => {
          data[nameFilter] = valueFilter.Valor;
        });

      setLoading(true);
      API.obtenerPrefacturas(data)
        .then((res) => {
          if (page === 1) setPrefacturas(res.Registros);
          else setPrefacturas([...prefacturas, ...res.Registros]);

          if (res.Registros?.length < PREFACTURAS_POR_PAGINA) setNextPage(0);
          else setNextPage(page + 1);

          setEsProveedor(res.EsProveedor);
        })
        .catch((error) => {
          if (error.code !== 401)
            toast.error("Error al obtener Prefacturas\n" + error.msg?.Message);
          API.DEVELOP && console.log(error);
        })
        .finally(() => setLoading(false));

      setTriggerGetPrefacturas(false);
    },
    [filters, prefacturas, showAnnulled, sortActive]
  );

  useEffect(() => {
    let isMounted = true;
    isMounted && triggerGetPrefacturas && getPrefacturas();
    return () => (isMounted = false);
  }, [triggerGetPrefacturas, getPrefacturas]);

  const getPagination = (page) => ({
    Pagina: page,
    CantidadRegistros: PREFACTURAS_POR_PAGINA,
  });

  const handleChangeFilter = (id, value) => {
    const filtrosHijos = metadataFilters.filter(
      (filter) => filter.FiltroPadre === id
    );

    const newFilters = { ...filters };
    if (typeof value === "string") {
      if (value === "") delete newFilters[id];
      else {
        newFilters[id] = { Valor: value };
        filtrosHijos.forEach(
          (filter) => delete newFilters[filter.NombreFiltro]
        );
      }
    } else {
      if (Object.keys(value).length === 0) delete newFilters[id];
      else {
        newFilters[id] = value;
        filtrosHijos.forEach(
          (filter) => delete newFilters[filter.NombreFiltro]
        );
      }
    }

    setFilters(newFilters);
    setTriggerGetPrefacturas(true);
  };

  const sort = (field, value) => {
    setSortActive({
      IdCampo: field,
      Orden: value,
    });
    setTriggerGetPrefacturas(true);
  };

  return (
    <S.Container>
      <Loading hidden={!loading} />
      <S.Filters count={metadataFilters.length}>
        {metadataFilters.map((filter) => {
          const lista = listas[filter.NombreFiltro];
          const nombreFiltro = filter.NombreFiltro;
          const currentOption = filters[nombreFiltro]
            ? {
                value: filters[nombreFiltro].Valor,
                label: filters[nombreFiltro].Etiqueta,
              }
            : { value: "" };

          switch (filter.Tipo) {
            case "text":
              return (
                <FieldText
                  key={filter.IdFiltro}
                  id={filter.NombreFiltro || ""}
                  label={filter.Etiqueta}
                  value={filters[filter.NombreFiltro]?.Valor || ""}
                  handleChange={handleChangeFilter}
                  readOnly={false}
                  required={false}
                  styles={"filter"}
                />
              );

            case "date":
              return (
                <FieldDate
                  key={filter.IdFiltro}
                  id={filter.NombreFiltro}
                  label={filter.Etiqueta}
                  value={
                    filters[filter.NombreFiltro]?.Valor.split("T")[0] || ""
                  }
                  handleChange={handleChangeFilter}
                  readOnly={false}
                  required={false}
                  styles={"filter"}
                />
              );

            case "enum":
              const options = lista?.Valores.map((option) => ({
                value: option.Valor,
                label: option.Etiqueta,
              }));

              return (
                <FieldList
                  key={filter.IdFiltro}
                  id={filter.NombreFiltro || ""}
                  type="enum"
                  label={filter.Etiqueta}
                  currentOption={currentOption}
                  options={options}
                  handleChange={handleChangeFilter}
                  canBeEmpty={true}
                  readOnly={false}
                  required={false}
                />
              );

            case "relate":
              return (
                <FieldListSearch
                  key={filter.IdFiltro}
                  id={filter.NombreFiltro || ""}
                  label={filter.Etiqueta}
                  listName={filter.NombreFiltro || ""}
                  currentOption={currentOption}
                  handleChange={handleChangeFilter}
                  canBeEmpty={true}
                  readOnly={false}
                  required={false}
                  parent={filter.FiltroPadre}
                  idParent={
                    filters[
                      metadataFilters.find(
                        (f) => f.NombreFiltro === filter.FiltroPadre
                      )?.IdFiltro
                    ]?.Valor
                  }
                />
              );

            default:
              return null;
          }
        })}
      </S.Filters>
      <S.Buttons>
        <S.ShowDeleted>
          <input
            id="showAnnulled"
            type="checkbox"
            checked={showAnnulled}
            onChange={(e) => {
              setShowAnnulled(e.target.checked);
              setTriggerGetPrefacturas(true);
            }}
          />
          <label htmlFor="showAnnulled" className="checkbox">
            &nbsp;Mostrar Anuladas
          </label>
        </S.ShowDeleted>
      </S.Buttons>
      <S.Table>
        <S.ColGroup>
          <S.Col />
          {metadataTable.map((campo) => (
            <S.Col key={campo.id} />
          ))}
          <S.Col />
        </S.ColGroup>
        <S.Tr>
          <S.Th />
          {metadataTable.map((campo) => (
            <S.Th key={campo.id} title={campo.etiqueta}>
              <S.HeaderColumn>
                <span>{campo.etiqueta}</span>
                {campo.ordenable && (
                  <Sort
                    field={campo.nombreCampo}
                    sortActive={sortActive}
                    onClick={(value) => sort(campo.nombreCampo, value)}
                  />
                )}
              </S.HeaderColumn>
            </S.Th>
          ))}
          <S.Th />
        </S.Tr>
        {prefacturas.map((prefactura) => (
          <RegistroPrefactura
            key={prefactura.id}
            metadataTable={metadataTable}
            prefactura={prefactura}
            listas={listas}
            type="prefacturas"
            update={() => setTriggerGetPrefacturas(true)}
            esProveedor={esProveedor}
            toast={toast}
            setLoading={setLoading}
            access={permissions}
          />
        ))}
      </S.Table>
      <S.Pagination>
        <button
          disabled={nextPage === 0}
          onClick={() => getPrefacturas(nextPage)}
        >
          {nextPage === 0
            ? "No hay más prefacturas"
            : "Mostrar más prefacturas..."}
        </button>
      </S.Pagination>
    </S.Container>
  );
};

Prefacturas.propTypes = {
  access: PropTypes.object,
};
